import api from 'api/'
import { AUTH, CLEAR_THIS_STATE } from '../definitions'
import { replace } from 'connected-react-router'
import { removeToken, setToken, setRefreshToken, removeLineState, getLineState, setRetryLineLogin } from 'utils/auth'
import alert from 'utils/alert'
import moment from 'moment'
import { showAlert } from 'store/actions/alert'
import { showLoader, hideLoader } from 'store/actions/loader'
import { getProfile, getProfileStat } from 'store/actions/profile'
import { getMasters } from '../actions/master'
import { unmountCashCouponGroup } from 'store/actions/coupons'
import { getRefreshToken, getToken, removeRefreshToken } from '../../utils/auth'

import { getVehicleLength } from 'store/actions/vehicles'

import { resetHashId } from 'utils/treasureData'
import { isMobileApp } from 'utils/validation'

import { ERROR, USER_TYPE } from 'config/constant'

import * as datadogRum from 'utils/datadog'

/**
 * Clear auth reducer
 */
const clearAuth = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH.CLEAR
      // data: {}
    })
  }
}

/**
 * By default, user will call api to log sign out activity
 * @param {*} skipLogging
 * Skip signout activity log, for some reason like token expired or error
 * that make app not able to create request
 */
const authSignout = (skipLogging = false) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { couponsCashGroup } = getState()

    /*istanbul ignore next 2019-11-22*/
    if (
      couponsCashGroup &&
      couponsCashGroup.set === true &&
      couponsCashGroup &&
      couponsCashGroup.ids.length > 0 &&
      window.location.pathname === '/coupons'
    ) {
      dispatch(unmountCashCouponGroup())
    }

    /*istanbul ignore next 2019-11-22*/
    if (couponsCashGroup && couponsCashGroup.set === false && window.location.pathname === '/coupons') {
      dispatch(unmountCashCouponGroup())
    }
    resetHashId()
    var promiseLog = new Promise(function (resolve, reject) {
      if (skipLogging === true) resolve()

      api
        .signOut()
        .then(() => resolve())
        .catch(reject)
    })

    return promiseLog
      .then(() => {
        removeToken()
        removeRefreshToken()
        datadogRum.clearCustomer()
        dispatch(hideLoader())
        dispatch({ type: CLEAR_THIS_STATE })
      })
      .catch(() => {
        dispatch(hideLoader())
      })
  }
}

/**
 * Sign action used in sign form or after sign up
 * @param {*} payload
 * @param {*} signInData
 */
const authSignin = (payload, signInData) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    const signInPayload = signInData
      ? signInData
      : {
          identifier: form.signin.values.identifier,
          password: form.signin.values.password,
          type: payload.type,
          rememberMe: isSafari ? true : payload.rememberMe
        }

    return api
      .signIn(signInPayload)
      .then((res) => {
        const promises = []

        if (res.data.refresh_token !== undefined && res.data.refresh_token !== null && res.data.refresh_token !== '') {
          promises.push(setRefreshToken(res.data.refresh_token))
        } else {
          removeRefreshToken()
        }

        const expireDate = new Date(res.data.valid_until)
        promises.push(setToken(res.data.token, expireDate))

        return Promise.all(promises)
      })
      .then(() => dispatch(replace('/')))
      .then(() => {
        return dispatch(getMasters())
      })
      .then(() => {
        dispatch(getVehicleLength())
      })
      .then(() => {
        dispatch({
          type: AUTH.SIGNUP,
          data: { isFirstLogin: payload.isFirstLogin }
        })
        return dispatch(getProfile())
      })
      .then(() => dispatch(getProfileStat()))
      .then(() => dispatch(hideLoader()))
      .catch((err) => {
        dispatch(
          showAlert(
            err && err.response
              ? alert(err.response.status, err.response.data.error_code, err.response.headers['x-account-lock-duration'])
              : alert(429, 49999)
          )
        )
        dispatch(hideLoader())
      })
  }
}

/**
 * authSigninLine to sign in using line login code after redirection
 * @param {Object} payload
 * @param {string} payload.code
 * @param {string} payload.state
 * @param {string} redirect
 * @param {number} type User type
 */
const authSigninLine = (payload, redirect, type) => {
  setRetryLineLogin()
  return (dispatch) => {
    dispatch(showLoader())
    if (getLineState() !== payload.state) {
      dispatch(showAlert(alert(401)))
      dispatch(hideLoader())
      return Promise.resolve()
    }

    // localStorage.setItem((new Date().toISOString), `payload.rememberMe: ${payload.rememberMe}`)
    return api
      .lineSignIn(payload.code, redirect, type, payload.rememberMe)
      .then((res) => {
        alert(res)
        if (res.status !== 200) {
          throw res
        }
        removeLineState()

        if (res && res.data.is_connected_line === false) {
          dispatch({
            type: AUTH.SIGNUP,
            data: { ...res.data }
          })
          dispatch(
            replace('/signin', {
              search: null
            })
          )
          dispatch(hideLoader())
          return res.data
        } else {
          dispatch(showLoader())
          const promises = []
          if (res.data.refresh_token !== undefined) {
            promises.push(setRefreshToken(res.data.refresh_token))
          } else {
            removeRefreshToken()

            // Prevent the below code from executing.
            throw new Error('Missing refresh token.')
          }

          promises.push(setToken(res.data.token, new Date(moment().add(2, 'years').toISOString())))

          return (
            Promise.all(promises)
              .then(() => dispatch(clearAuth()))
              .then(() => dispatch(getMasters()))
              .then(() => dispatch(getProfile()))
              .then(() => dispatch(getProfileStat()))
              // .then(() => dispatch(replace(`/`)))
              .then(() => dispatch(hideLoader()))
              .catch(/* istanbul ignore next */ () => dispatch(hideLoader()))
          )
        }
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(replace(`/signin`))
        dispatch(hideLoader())
      })
  }
}

/**
 * authSignup is used to verify identity before create account
 * @param {*} selectedType
 * 1 for individual, 2 corporate, 3 controller
 * @param {*} selectedId
 * For individual user, they can choose either to verify using national id or passport
 * @param {*} token
 * For corporate account, they will go to this page from email verification email and has token in url
 */
const authSignup = (selectedType, selectedId, token) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()

    let signUpPayload = {}
    if (selectedType === 1 && selectedId === 'id' && form.signup.values) {
      signUpPayload.national_id = form.signup.values.individual_national_id
    }
    if (selectedType === 1 && selectedId === 'passport' && form.signup.values) {
      signUpPayload.passport_no = form.signup.values.individual_passport
    }
    if (selectedType === 2 || selectedType === 3) {
      signUpPayload.invite_token = token
    }

    let authData = {}
    if (selectedType === 1) {
      return new Promise(function (resolve, reject) {
        api
          .signUpIndividual(signUpPayload)
          .then((res) => {
            authData.type = 1
            selectedId === 'id' ? (authData.national_id = signUpPayload.national_id) : (authData.passport = signUpPayload.passport_no)
            authData.given_name = res.data.given_name
            authData.surname = res.data.surname
            authData.mobile_phone = res.data.mobile_phone
            authData.home_phone = res.data.home_phone
            authData.email = res.data.email

            dispatch({
              type: AUTH.SIGNUP,
              data: authData
            })

            dispatch(hideLoader())
            resolve(res)
          })
          .catch((err) => {
            if (err && err.response) {
              /* istanbul ignore else */ if (err.response.status !== 400 && err.response.status !== 409) {
                dispatch(showAlert(alert(err.response.status, err.response.data.error_code)))
              }
            } else {
              dispatch(showAlert(alert(400, '')))
            }

            dispatch(hideLoader())
            reject(err)
          })
      })
    } else if (selectedType === 3) {
      return new Promise(function (resolve, reject) {
        api
          .signUpController(signUpPayload)
          .then((res) => {
            authData.type = 3
            authData.username = res.data.username
            authData.email = res.data.email
            authData.mobile_phone = res.data.mobile_phone
            authData.invite_token = token
            authData.registration_token = res.data.registration_token // bypass OTP

            dispatch({
              type: AUTH.SIGNUP,
              data: authData
            })

            dispatch(hideLoader())
            resolve()
          })
          .catch((err) => {
            dispatch(
              showAlert(
                err && err.response
                  ? alert(err.response.status, err.response.data.error_code)
                  : /*istanbul ignore next 2019-11-22*/ alert(0, '')
              )
            )
            dispatch(hideLoader())
            reject(err)
          })
      })
    } else {
      return new Promise(function (resolve, reject) {
        api
          .signUpCorporate(signUpPayload)
          .then((res) => {
            authData.type = 2
            authData.invite_token = signUpPayload.invite_token
            authData.company_name = res.data.company_name
            authData.company_prefix = res.data.company_prefix
            authData.company_reg_id = res.data.company_reg_id
            authData.company_display_name = res.data.company_display_name
            authData.given_name = res.data.given_name
            authData.surname = res.data.surname
            authData.mobile_phone = res.data.mobile_phone
            authData.office_phone = res.data.office_phone
            authData.office_phone_ext = res.data.office_phone_ext
            authData.email = res.data.email

            dispatch({
              type: AUTH.SIGNUP,
              data: authData
            })

            dispatch(hideLoader())
            resolve(res)
          })
          .catch((err) => {
            dispatch(
              showAlert(
                err && err.response
                  ? alert(err.response.status, err.response.data.error_code)
                  : /*istanbul ignore next 2019-11-22*/ alert(0, '')
              )
            )
            dispatch(hideLoader())
            reject(err)
          })
      })
    }
  }
}

/**
 *
 */
const authRegistration = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())

      const { form, auth } = getState()

      let createAccountPayload = {}

      /* istanbul ignore else */
      if (auth.type === 1) {
        /* istanbul ignore else */
        if (auth.line_uid && auth.line_uid !== '') {
          createAccountPayload.line_uid = auth.line_uid
        }
        /* istanbul ignore else */
        if (auth.national_id) {
          createAccountPayload.national_id = auth.national_id
        }
        /* istanbul ignore else */
        if (auth.passport) {
          createAccountPayload.passport_no = auth.passport
        }
        /* istanbul ignore else */
        if (form.createAccount.values.mobile_phone) {
          createAccountPayload.mobile_phone = form.createAccount.values.mobile_phone
        }
        /* istanbul ignore else */
        if (form.createAccount.values.home_phone) {
          createAccountPayload.home_phone = form.createAccount.values.home_phone
        }
        /* istanbul ignore else */
        if (form.createAccount.values.email) {
          createAccountPayload.email = form.createAccount.values.email
        }
        /* istanbul ignore else */
        if (form.createAccount.values.username) {
          createAccountPayload.username = form.createAccount.values.username
        }
      }

      if (auth.type === 2) {
        /*istanbul ignore else*/
        if (form.createAccount.values.mobile_phone) {
          createAccountPayload.mobile_phone = form.createAccount.values.mobile_phone
        }
        /*istanbul ignore else*/
        if (form.createAccount.values.office_phone) {
          createAccountPayload.office_phone = form.createAccount.values.office_phone
        }
        /*istanbul ignore else*/
        if (form.createAccount.values.email) {
          createAccountPayload.email = form.createAccount.values.email
        }
        /*istanbul ignore else*/
        if (form.createAccount.values.office_phone_ext) {
          createAccountPayload.office_phone_ext = form.createAccount.values.office_phone_ext
        }

        createAccountPayload.invite_token = auth.invite_token
        createAccountPayload.given_name = form.createAccount.values.given_name
        createAccountPayload.surname = form.createAccount.values.surname
        createAccountPayload.username = form.createAccount.values.username
        createAccountPayload.password = form.createAccount.values.password
      }

      if (auth.type === 3) {
        createAccountPayload.invite_token = auth.invite_token
        createAccountPayload.given_name = form.createAccount.values.given_name
        createAccountPayload.surname = form.createAccount.values.surname

        /*istanbul ignore else*/
        if (form.createAccount.values.national_id) {
          createAccountPayload.national_id = form.createAccount.values.national_id
        }

        /*istanbul ignore else*/
        if (form.createAccount.values.passport) {
          createAccountPayload.passport_no = form.createAccount.values.passport
        }

        /*istanbul ignore else*/
        if (form.createAccount.values.mobile_phone) {
          createAccountPayload.mobile_phone = form.createAccount.values.mobile_phone
        }

        /*istanbul ignore else*/
        if (form.createAccount.values.office_phone) {
          createAccountPayload.office_phone = form.createAccount.values.office_phone
        }

        if (form.createAccount.values.office_phone_ext) {
          createAccountPayload.office_phone_ext = form.createAccount.values.office_phone_ext
        }

        if (form.createAccount.values.email) {
          createAccountPayload.email = form.createAccount.values.email
        }
      }

      createAccountPayload.password = form.createAccount.values.password

      // const getLocation = new Promise(resolve => {
      //   navigator.geolocation.getCurrentPosition(
      //     function (pos) {
      //       createAccountPayload.created_latitude = pos.coords.latitude
      //       createAccountPayload.created_longitude = pos.coords.longitude
      //       resolve(createAccountPayload)
      //     },
      //     /*istanbul ignore next */
      //     () => {
      //       resolve(createAccountPayload)
      //     },
      //     {
      //       enableHighAccuracy: true,
      //       timeout: 5000,
      //       maximumAge: 0
      //     }
      //   )
      // })

      const getLocation = new Promise((resolve) => {
        resolve(createAccountPayload)
      })

      getLocation
        .then((payload) => {
          const isFromMobile = isMobileApp()
          if (auth.type === 1) {
            payload.is_mobile = isFromMobile
            return api.registrationIndividual(payload)
          } else if (auth.type === 2) {
            // use requestBody instead of payload because corporate registration api doesn't allow geolocation create_latitude, created_longitude
            const requestBody = {
              invite_token: payload.invite_token,
              email: payload.email,
              given_name: payload.given_name,
              surname: payload.surname,
              mobile_phone: payload.mobile_phone,
              office_phone: payload.office_phone,
              office_phone_ext: payload.office_phone_ext,
              username: payload.username,
              password: payload.password
            }

            return api.registrationCorporateSignup(requestBody)
          } else if (auth.type === 3) {
            return api.registrationController(payload)
          } else {
            // for passing test
            /*istanbul ignore else */
            if (!auth.type) {
              throw new Error()
            }
          }
        })
        .then((res) => {
          // Using old registration flow if corporate
          if (auth.type === 2) {
            const payload = {
              rememberMe: true,
              isFirstLogin: true
            }

            const signInPayload = {
              identifier: createAccountPayload.username,
              password: createAccountPayload.password,
              type: 2
            }
            return dispatch(authSignin(payload, signInPayload))
          }
          // If individual and corporate and fail to get registration_id
          /* istanbul ignore next */
          if (!(res && res.data && res.data.registration_id)) {
            return Promise.reject(
              new Error(
                `[authRegistration] registration_id is missing from response ${JSON.stringify(res && res.data && res.data.identifier)}`
              )
            )
          }
          // If get registration_id
          dispatch({
            type: AUTH.SIGNUP,
            data: {
              registration_id: res.data.registration_id,
              identifier: res.data.identifier,
              mobile_phone: createAccountPayload.mobile_phone,
              username: form.createAccount.values.username,
              password: createAccountPayload.password,
              registration_token: res.data.registration_token // bypass OTP
            }
          })
          /* istanbul ignore next */
          dispatch(hideLoader())
          /* istanbul ignore next */
          resolve()
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response ? /* istanbul ignore next */ alert(err.response.status, err.response.data.error_code) : alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const authCorporateRegistration = () => async (dispatch, getState) => {
  dispatch(showLoader())

  try {
    const { form: { createAccount } = {} } = getState()

    await api.registrationCorporate({
      username: createAccount.values.username,
      email: createAccount.values.email,
      mobile_phone: createAccount.values.mobile_phone
    })
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
    throw err
  } finally {
    dispatch(hideLoader())
  }
}

const authRegistrationResend = () => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      const { auth } = getState()

      const resend = new Promise((resolve, reject) => {
        /* istanbul ignore else */
        if (!auth.registration_id) {
          return reject(new Error('[authRegistrationResend] registration_id is missing from Redux state'))
        }
        if (auth.type === 1) {
          resolve(api.registrationIndividualResend(auth.registration_id))
        } else if (auth.type === 3) {
          resolve(api.registrationControllerResend(auth.registration_id))
        } else {
          dispatch(hideLoader())
          return reject(new Error(`[authRegistrationResend] User type not allowed: ${auth.type}`))
        }
      })

      resend
        .then((res) => {
          dispatch(hideLoader())
          dispatch(showAlert(alert(res ? res.status : 200, 'forgot-password')))
          resolve(res)
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? /*istanbul ignore next 2019-11-22*/ alert(err.response.status, err.response.data.error_code)
                : alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const authRegistrationVerify = () => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      const { auth, form } = getState()

      const verify = new Promise((resolve, reject) => {
        if (!auth.registration_id) {
          return reject(new Error(`[authRegistrationVerify] registration_id is missing from Redux state.`))
        }

        if (!(form && form.createAccount && form.createAccount.values && form.createAccount.values.otp)) {
          return reject(new Error(`[authRegistrationVerify] otp is missing from Redux state.`))
        }

        if (auth.type === 1) {
          resolve(api.registrationIndividualVerify(auth.registration_id, form.createAccount.values.otp))
        } else if (auth.type === 3) {
          resolve(api.registrationControllerVerify(auth.registration_id, form.createAccount.values.otp))
        } else {
          dispatch(hideLoader())
          return reject(new Error(`[authRegistrationVerify] User type not allowed: ${auth.type}`))
        }
      })

      verify
        .then((res) => {
          if (!(res && res.data && res.data.registration_token)) throw new Error()
          dispatch({
            type: AUTH.SIGNUP,
            data: {
              registration_token: res.data.registration_token
            }
          })

          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const authRegistrationSignup = () => async (dispatch, getState) => {
  dispatch(showLoader())

  const { auth } = getState()

  if (!auth.registration_token) {
    throw new Error('Missing registration_token')
  }

  const signUpByUserType = (userType, registrationToken) => {
    if (userType === USER_TYPE.INDIVIDUAL) {
      return api.registrationIndividualSignup(registrationToken)
    }

    if (userType === USER_TYPE.CONTROLLER) {
      return api.registrationControllerSignup(registrationToken)
    }

    throw new Error(`Cannot signup for this user type: ${userType}`)
  }

  try {
    await signUpByUserType(auth.type, auth.registration_token)

    await dispatch(
      authSignin(
        {
          rememberMe: true
        },
        {
          identifier: auth.username || auth.national_id || auth.passport,
          password: auth.password,
          type: auth.type,
          rememberMe: true
        }
      )
    )
  } catch (error) {
    const alertPayload = error && error.response ? alert(error.response.status, error.response.data.error_code) : alert(0, '')

    dispatch(showAlert(alertPayload))
    dispatch(hideLoader())

    throw error
  }
}

/**
 * Used to submit a request for forgot password
 * @param {*} accountTypeId
 * @param {*} channel
 */
const requestPasswordReset = (accountTypeId, channel) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()

    const payload = {
      type: accountTypeId,
      identifier: form.signinIssue.values.identifier,
      channel: channel
    }

    return new Promise((resolve, reject) => {
      api
        .requestPasswordReset(payload)
        .then((res) => {
          dispatch({
            type: AUTH.SIGNUP,
            data: res.data
          })
          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code, channel)
                : /*istanbul ignore next 2019-11-22*/ alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * To request resend otp
 */
const resendPassword = () => {
  return (dispatch, getState) => {
    dispatch(showLoader())
    const { auth } = getState()

    return new Promise((resolve, reject) => {
      if (typeof auth.request_id !== 'string') {
        return reject(new TypeError(`[resendPassword] Expect string request_id, but got ${auth.request_id}`))
      }

      api
        .requestPasswordResend(auth.request_id)
        .then((res) => {
          dispatch(hideLoader())
          dispatch(showAlert(alert(res ? res.status : /*istanbul ignore next 2019-11-22*/ 200, 'forgot-password')))
          resolve(res)
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code)
                : /*istanbul ignore next 2019-11-22*/ alert(500, 'forgot-password')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * To verify otp
 */
const requestPasswordResetVerify = (eventError) => {
  return (dispatch, getState) => {
    dispatch(showLoader())
    const { auth, form } = getState()

    const payload = {
      code: form.signinIssue.values.otp
    }

    return new Promise((resolve, reject) => {
      if (typeof auth.request_id !== 'string') {
        return reject(new TypeError(`[requestPasswordResetVerify] Expect string request_id, but got ${auth.request_id}`))
      }

      api
        .requestPasswordResetVerify(auth.request_id, payload)
        .then((res) => {
          dispatch({
            type: AUTH.SIGNUP,
            data: res.data
          })
          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          if (err.response.data.error_code === ERROR.ERROR_OTP) {
            eventError()
          }
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code)
                : /*istanbul ignore next 2019-11-22*/ alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * To reset the password after succesfully verify otp
 */
const resetPassword = () => {
  return (dispatch, getState) => {
    dispatch(showLoader())
    const { form, auth } = getState()

    return new Promise((resolve, reject) => {
      if (typeof auth.reset_token !== 'string') {
        return reject(new TypeError(`[resetPassword] Expect string reset_token, but got ${auth.reset_token}`))
      }

      const payload = {
        reset_token: auth.reset_token,
        password: form.signinIssue.values.password
      }

      api
        .resetPassword(payload)
        .then((res) => {
          dispatch({
            type: AUTH.SIGNUP,
            data: res.data
          })
          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code)
                : /*istanbul ignore next 2019-11-22*/ alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * For forgot account, similar to forgot password,
 * difference is it will retrieve user data to selected channel
 * @param {*} accountTypeId
 * @param {*} channel
 */
const forgotAccount = (accountTypeId, channel) => {
  return (dispatch, getState) => {
    dispatch(showLoader())

    const { form } = getState()

    return new Promise((resolve, reject) => {
      const payload = {
        type: accountTypeId,
        channel: channel,
        ...form.signinIssue.values
      }

      api
        .forgotAccount(payload)
        .then((res) => {
          dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(
            showAlert(
              err && err.response
                ? alert(err.response.status, err.response.data.error_code)
                : /*istanbul ignore next 2019-11-22*/ alert(0, '')
            )
          )
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const revokeRefreshToken = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      const refreshToken = getRefreshToken()

      if (getToken() === undefined && refreshToken !== undefined) {
        api
          .refreshToken({ refreshToken: refreshToken })
          .then((res) => res.json())
          .then(async (res) => {
            if (res.token !== undefined) {
              setToken(res.token, new Date(res.valid_until))
                .then(() => dispatch(getMasters()))
                .then(() => dispatch(getProfile()))
                .then(() => dispatch(getProfileStat()))
            } /*  else {
            await removeRefreshToken()
          } */
            dispatch(hideLoader())
            resolve(res.token !== undefined)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  }
}

export {
  authSignin,
  authSignup,
  authSignout,
  authRegistration,
  authRegistrationVerify,
  authRegistrationSignup,
  authCorporateRegistration,
  authSigninLine,
  requestPasswordReset,
  requestPasswordResetVerify,
  resetPassword,
  forgotAccount,
  resendPassword,
  clearAuth,
  authRegistrationResend,
  revokeRefreshToken
}
