import React from 'react'
import { FormattedMessage } from 'react-intl'

// Classnames
import classnames from 'classnames'

// Proptypes
import PropTypes from 'prop-types'

// Method
import * as format from 'utils/format'
import * as helper from 'utils/helper'

// Styles
import './_index.scss'

import { isEmpty } from 'lodash'

const CouponItemModal = ({ detail }) => {
  const classes = classnames(
    'coupon-modal',
    { 'coupon-modal--mileage': detail.coupon_info.coupon_category === 'CC' },
    { 'coupon-modal--discount': detail.coupon_info.coupon_category === 'SC' }
  )

  const term = detail.coupon_info.terms.split('|')

  const renderTitleOwnerIdentity = () => {
    if (detail.user?.national_id || detail.user?.passport_no) {
      return (
        <div>
          <FormattedMessage id='coupon.detail.ownerId' defaultMessage='coupon.detail.ownerId' />
        </div>
      )
    }

    if (detail.user?.company_reg_id || detail.corporate?.company_reg_id) {
      return (
        <div>
          <FormattedMessage id='coupon.detail.ownerCorpId' defaultMessage='coupon.detail.ownerCorpId' />
        </div>
      )
    }

    return ''
  }

  return (
    <div className={classes}>
      <div className='coupon-modal__info'>
        <div className='coupon-modal__info__header name'>{detail.coupon_info.name}</div>
        <div className='coupon-modal__info__name'>{detail.coupon_info.title}</div>
        {detail.coupon_info.cash_coupon_number && (
          <div className='coupon-modal__info__cash-number'>{detail.coupon_info.cash_coupon_number}</div>
        )}
      </div>
      {detail.coupon_info.coupon_category === 'CC' && detail.coupon_info.owner_id && !detail.coupon_info.license_plate && (
        <div className='coupon-modal__info'>
          <div className='coupon-modal__info__header'>{renderTitleOwnerIdentity()}</div>
          <div className='coupon-modal__info__vehicle-name'>{format.maskOwnerId(detail.coupon_info.owner_id)}</div>
        </div>
      )}
      {!isEmpty(detail.vehicle) && (detail.coupon_info.coupon_category === 'SC' || detail.coupon_info.coupon_category === 'CC') && (
        <>
          <div className='coupon-modal__info'>
            <div className='coupon-modal__info__header'>
              <FormattedMessage id='coupon.detail.licensePlate' defaultMessage='coupon.detail.licensePlate' />
            </div>
            <div className='coupon-modal__info__vehicle-name'>{detail.vehicle.license_plate}</div>
          </div>
          <div className='coupon-modal__info'>
            <div className='coupon-modal__info__header'>
              <FormattedMessage id='common.labels.vin' defaultMessage='common.labels.vin' />
            </div>
            <div className='coupon-modal__info__terms'>{detail.vehicle.vin}</div>
          </div>
        </>
      )}

      <div className='coupon-modal__info'>
        <div className='coupon-modal__info__header'>
          <FormattedMessage id='coupon.detail.benefits' defaultMessage='coupon.detail.benefits' />
        </div>
        <div id='coupon-modal__info__id' dangerouslySetInnerHTML={{ __html: detail.coupon_info.benefits }}></div>
      </div>

      <div className='coupon-modal__info'>
        <div className='coupon-modal__info__header'>
          <FormattedMessage id='coupon.detail.usageNotes' defaultMessage='coupon.detail.usageNotes' />
        </div>
        {term.length > 1 ? (
          <>
            <div className='coupon-modal__info__terms' dangerouslySetInnerHTML={{ __html: term[0] }}></div>
            <div className='coupon-modal__info__terms' dangerouslySetInnerHTML={{ __html: term[1] }}></div>
          </>
        ) : (
          <div className='coupon-modal__info__terms' dangerouslySetInnerHTML={{ __html: term[0] }}></div>
        )}
      </div>

      <div className='coupon-modal__info'>
        <div className='coupon-modal__info__header'>
          <FormattedMessage
            id='coupon.detail.expiry'
            defaultMessage='coupon.detail.expiry'
            values={{ value: detail.coupon_info.asterisk === true ? '*' : '' }}
          />
        </div>
        <div className='coupon-modal__info'>{format.showDate(detail.valid_until)}</div>
      </div>

      {detail.coupon_info.coupon_category === 'CC' && (
        <div className='coupon-modal__info'>
          <div className='coupon-modal__info__header'>
            <FormattedMessage id='coupon.detail.usageTimes' defaultMessage='coupon.detail.usageTimes' />
          </div>
          {detail.is_unlimited_usage ? (
            <FormattedMessage
              values='coupon.detail.unlimitedCashCoupsDetail'
              id='coupon.detail.unlimitedCashCoupsDetail'
              defaultMessage='coupon.detail.unlimitedCashCoupsDetail'
            />
          ) : (
            <FormattedMessage
              values={{ value: detail.remaining }}
              id='coupon.detail.timesValues'
              defaultMessage='coupon.detail.timesValues'
            />
          )}
        </div>
      )}
    </div>
  )
}

CouponItemModal.propTypes = {
  detail: PropTypes.shape({
    valid_until: PropTypes.string,
    status: PropTypes.number,
    coupon_info: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      benefits: PropTypes.string,
      terms: PropTypes.string
    })
  })
}

CouponItemModal.defaultProps = {
  detail: {
    valid_until: null,
    status: null,
    coupon_info: {
      name: null,
      type: null,
      title: null,
      benefits: null,
      terms: null
    },
    vehicle: {
      vehicle_info: {},
      model: {
        marketing_name: null
      },
      user_vehicle: {
        name: null
      },
      vin: null,
      engine_no: null,
      chassis_no: null,
      license_plate: null
    }
  }
}

export default CouponItemModal
