import React, { useState, useRef, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Proptypes
import PropTypes from 'prop-types'

// Components
import Button from 'components/elements/Button'
import List from 'components/elements/List'
import CouponItem from 'domain/Coupons/components/CouponItem'
import ListPager from 'components/elements/ListPager'
import ContentHeader from 'components/layouts/ContentHeader'

// Methods

import { postPageView } from 'store/actions/profile'
// Actions
import { getCoupons, clearCashCouponGroup, unmountCashCouponGroup } from 'store/actions/coupons'
import { clearFilterCoupon } from 'store/actions/filterCoupons'

import noCoupon from 'assets/images/nocoupon.svg'

// Utils
import { checkError } from 'utils/helper'

import './_index.scss'

const limit = 10

const Coupons = ({
  cashGroup,
  coupons,
  checkAll,
  profile,
  getCoupons,
  clearCashCouponGroup,
  clearFilterCoupon,
  unmountCashCouponGroup
}) => {
  const listPagingRef = useRef()
  const listRef = useRef()
  const [allBtn, setAllBtn] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        /* istanbul ignore else */
        // ** Used when ordered by and sortby is used on coupon group
        if (cashGroup && cashGroup.set) {
          showAllCouponsBtn(true)
          await getCoupons(true, 0, limit, cashGroup.data.cc, cashGroup.data.exp, cashGroup.data.ty, cashGroup.data.val, cashGroup.data.vid)
        } else {
          unmountCashCouponGroup()
          showAllCouponsBtn(false)
          await getCoupons(true, 0, limit)
        }
      } catch (error) {
        checkError(error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // When unmounting after a cash group click from home
        /* istanbul ignore else */
        if (window.location.pathname !== '/signin' && cashGroup) {
          clearFilterCoupon()
          unmountCashCouponGroup()
        }
      } catch (error) {
        checkError(error)
      }
    }

    fetchData()
  }, [])

  const listPagingChangePage = async (offset) => {
    try {
      if (cashGroup && cashGroup.set === true) {
        await getCoupons(
          true,
          offset,
          limit,
          cashGroup.data.cc,
          cashGroup.data.exp,
          cashGroup.data.ty,
          cashGroup.data.val,
          cashGroup.data.vid
        )
      } else {
        await getCoupons(true, offset, limit)
      }
    } catch (error) {
      checkError(error)
    }
  }

  const getAllCoupons = async () => {
    try {
      showAllCouponsBtn(false)
      clearFilterCoupon()
      unmountCashCouponGroup()
      await getCoupons(true, 0, limit)
    } catch (error) {
      checkError(error)
    }
  }

  const showAllCouponsBtn = (bool) => {
    setAllBtn(bool)
  }
  return (
    <div className='coupons'>
      <div className='coupons__header'>
        <ContentHeader title={'coupon.labels.title'} subTitle={'coupon.labels.subTitle'} />
        <div className='coupons__header__pager'>
          {allBtn && (
            <Button
              className='coupons__header__pager__all-coupons'
              color='primary'
              label='coupon.labels.allCoupons'
              onClick={getAllCoupons}
            />
          )}
          {!coupons.data.some((e) => e.is_checked) && (
            <ListPager
              checkAll={checkAll}
              limit={limit}
              total={coupons.total_count}
              ref={listPagingRef}
              reduxActionGet={listPagingChangePage}
            ></ListPager>
          )}
        </div>
      </div>
      {coupons.total_count === 0 && coupons.isReload ? (
        <div className='coupons__body-no-coupon'>
          <div className='coupons__body-no-coupon__content'>
            <div className='coupons__body-no-coupon__content__img'>
              <img src={noCoupon} alt='' />
            </div>
            <div className='coupons__body-no-coupon__content__text'>
              <FormattedMessage id='common.labels.noCoupon' defaultMessage='common.labels.noCoupon' />
            </div>
          </div>
        </div>
      ) : (
        <div className='coupons__body' id='coupons-list'>
          <List
            ref={listRef}
            component={CouponItem}
            userType={profile.type}
            data={coupons.data}
            totalCount={coupons.total_count}
            height={210}
            adaptable
            fromPage='Coupon List'
          />
        </div>
      )}
    </div>
  )
}

Coupons.propTypes = {
  getCoupons: PropTypes.func,
  clearCashCouponGroup: PropTypes.func,
  unmountCashCouponGroup: PropTypes.func,
  clearFilterCoupon: PropTypes.func,
  keyword: PropTypes.string,
  orderBy: PropTypes.number,
  sortBy: PropTypes.number,
  coupons: PropTypes.object,
  checkAll: PropTypes.func,
  profile: PropTypes.object,
  cashGroup: PropTypes.object
}

const mapStateToProps =
  /*istanbul ignore next*/
  (state) => {
    let coupons = JSON.parse(JSON.stringify(state.coupons))

    if (state.couponsCashGroup.set && coupons && Array.isArray(coupons.data) && coupons.data.length > 0) {
      coupons.data[0].onDidMountOpenModal = true
    }

    return {
      profile: state.profile,
      coupons: coupons,
      keyword: state.filterCoupons.keyword,
      orderBy: state.filterCoupons.orderBy,
      sortBy: state.filterCoupons.sortBy,
      cashGroup: state.couponsCashGroup
    }
  }

const mapDispatchToProps =
  /*istanbul ignore next*/
  (dispatch) =>
    bindActionCreators(
      {
        getCoupons,
        clearCashCouponGroup,
        clearFilterCoupon,
        unmountCashCouponGroup,
        postPageView
      },
      dispatch
    )

export default connect(mapStateToProps, mapDispatchToProps)(Coupons)
