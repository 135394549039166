import axios from 'axios'
import env from 'config/environment'
import 'moment/locale/th'
import { getToken } from 'utils/auth'
import { checkLanguage } from 'utils/languages'
import queryString from 'query-string'

require('es6-promise').polyfill()
require('isomorphic-fetch')

const axiosInstance = axios.create({
  baseURL: env.apiV3
})

const apiV3 = {
  getCouponsByVehicleHashId: (hashId) => {
    return axiosInstance.get(`/api/vehicles/${hashId}/coupons`)
  },
  getCoupons: (query) => {
    return axiosInstance.get(`/api/coupons?${queryString.stringify(query)}`)
  }
}

axiosInstance.interceptors.request.use(
  /*istanbul ignore next*/
  (config) => {
    const auth = getToken()
    const accLang = checkLanguage() === 'th' ? 'th-TH' : 'en-US'

    if (auth && !config.isAmazon && !config.isDownload) {
      config.headers.authorization = 'Bearer ' + auth
      config.headers['Accept-Language'] = accLang
    }

    if (config.isAmazon) {
      config.headers['Content-Type'] = config.data.type
      config.headers['Cache-Control'] = 'private, max-age=31536000'
    }
    if (config.isDownload) {
      config.headers['Cache-Control'] = 'no-cache'
    }

    return config
  }
)

// Add a 401 response interceptor
/*istanbul ignore next*/
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { apiV3 }
