export const ENV = { DEV: 'DEV', UAT: 'UAT', PROD: 'PROD' }

const getCouponCallMyiV3Enabled = () => {
  if (!process.env.REACT_APP_COUPON_CALL_MYI_V3_ENABLED) {
    return false
  }

  return typeof process.env.REACT_APP_COUPON_CALL_MYI_V3_ENABLED === 'string' && process.env.REACT_APP_COUPON_CALL_MYI_V3_ENABLED === 'true'
}

const DEV = {
  api: 'https://my-api-dev.isuzu-tis.com',
  apiV3: 'https://myi-service-v3-dev.isuzu-tis.com',
  base: process.env.REACT_APP_SELF_HOST_URL || 'https://appdev.my.isuzu-tis.com',
  line: '1656215903',
  envdom: process.env.REACT_APP_COOKIES_DOMAIN,
  cdn: 'https://cdndev.my.isuzu-tis.com',
  mux: 'https://mux-dev.isuzu-tis.com',
  couponCallMyiV3Enabled: getCouponCallMyiV3Enabled()
}

const UAT = {
  api: 'https://my-api-stg.isuzu-tis.com',
  apiV3: 'https://myi-service-v3-stg.isuzu-tis.com',
  base: 'https://appuat.my.isuzu-tis.com',
  line: '1656215903',
  envdom: '.isuzu-tis.com',
  cdn: 'https://cdnuat.my.isuzu-tis.com',
  mux: 'https://mux-stg.isuzu-tis.com',
  couponCallMyiV3Enabled: getCouponCallMyiV3Enabled()
}

const PROD = {
  api: 'https://my-api-prd.isuzu-tis.com',
  apiV3: 'https://myi-service-v3-prd.isuzu-tis.com',
  base: 'https://my.isuzu-tis.com',
  line: '1588420520',
  envdom: '.isuzu-tis.com',
  cdn: 'https://cdn.my.isuzu-tis.com',
  mux: 'https://mu-xtheexclusive.isuzu-tis.com',
  couponCallMyiV3Enabled: getCouponCallMyiV3Enabled()
}

const env =
  process.env.REACT_APP_ENV.trim() === ENV.DEV
    ? DEV
    : process.env.REACT_APP_ENV.trim() === ENV.UAT
    ? UAT
    : process.env.REACT_APP_ENV.trim() === ENV.PROD
    ? PROD
    : DEV

export const tilWebsiteUrl = 'https://bit.ly/3AhaJfu'
export const tilAppKey = 'cRfUjXn2r5u8x/A?D(G+KaPdSgVkYp3s'

export const appStoreUrl = 'https://apps.apple.com/us/app/my-isuzu/id1612415578'
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.tis.myisuzu'
export const appLink = 'myi://'

export default env
